@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Red Hat Display', Arial, sans-serif;
  background-color: #000;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

.spacer {
  height: 20vw; 
  width: 100%;
}

/* Default Navbar Styling */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 68vw;
  background-color: #000;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding-left: 16%;
  padding-right: 16%;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5vw 1vw;
  padding-top: 1.7vw;
  transition: background-color 0.3s;
}

.navbar-icons img {
  padding-top: 3px;
  align-content: center;
}


.navbar a:hover {
  color: #A2EB5E;
  border-radius: 5px;
  background-color: #000;
  opacity: 1;
}

.navbarImageInsta {
  width: 2.41vw;
  height: 2.41vw;
}

.buttonInsta{
  margin: 0;
  background: none;
  border: none;
  background-color: #000;
  cursor: pointer;
}

.navbarImageLine {
  height: 2.66vw;
  padding-left: 1vw;
}   

.navbarImageLogo {
  width: 2.41vw;
  height: 2.41vw;
  padding-left: 1vw;
}


/* Burger icon */
.burger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  margin: 3px 0;
  background-color: white;
}

/* Navbar Links */
.navbar-links {
  display: flex;
}

.navbar-links a {
  display: block;
}

/* Mobile Menu (hidden by default) */

@media (max-width: 1500px) {
  .navbarImageInsta {  
    width: 2.81vw;
    height: 2.81vw;
  }
  .navbarImageLine {
    height: 3.01vw;
  }
  .navbarImageLogo {
    width: 2.81vw;
    height: 2.81vw;
  }
  
}

@media (max-width: 1100px) {
  .navbar-links {
    display: none;
    width: 100%;
    text-align: center;
    position: absolute;
    background-color: #000;
    opacity: 0.9;
    flex-direction: column;
    padding: 20px 0;
    top: 50px;
    left: 0;
  }

  .navbar-links a {
    padding: 10px 0;
  }

  .burger {
    display: flex;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbarImageInsta {  
    width: 3.21vw;
    height: 3.21vw;
  }
  .navbarImageLine {
    height: 3.41vw;
  }
  .navbarImageLogo {
    width: 3.21vw;
    height: 3.21vw;
  }
}


@media (max-width: 768px) { 
  .navbarImageInsta {  
    width: 3.81vw;
    height: 3.81vw;
  }
  .navbarImageLine {
    height: 4.01vw;
  }
  .navbarImageLogo {
    width: 3.81vw;
    height: 3.81vw;
  }
    
}

@media (max-width: 490px) {
  .navbarImageInsta {  
    width: 4.61vw;
    height: 4.61vw;
  }
  .navbarImageLine {
    height: 4.81vw;
  }
  .navbarImageLogo {
    width: 4.61vw;
    height: 4.61vw;
  }
  
}


.header {
  background: linear-gradient(to bottom, #0d1117, #161b22);
  width: 100vw;
  height: 50vw; 
  text-align: center;
}

.header-video {
  width: 100vw;
  height: 50vw;
  object-fit: cover;
  opacity: 0.5;
}


@media (max-width: 1500px) {
  .header {
    height: 60vw; 
  }
  .header-video {
    height: 60vw; 
  }
  
}

@media (max-width: 768px) {
  .header {
    height: 70vw; 
  }

  .header-video {
    height: 70vw; 
  }
}

@media (max-width: 490px) {
  .header {
    height: 80vw; 
  }

  .header-video {
    height: 80vw; 
  }
  
}

.main-content {
  padding-left: 16%;
  padding-right: 16%;

}

.description h1 {
  font-size: 4rem;
  margin-bottom: 10px;
}

.description h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #A2EB5E;
}

.description p {
  font-size: 1.5rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .description h1 {
    font-size: 3rem;
  }
  .description h2 {
    font-size: 2rem;
  }
  .description p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .description h1 {
    font-size: 2rem;
  }
  .description h2 {
    font-size: 1.5rem;
  }
  .description p {
    font-size: 1rem;
  }
}


.features {
  width: 100%;
}

.features .featureContent {
padding-left: 0.52vw;
padding-right: 0.52vw;

}

.features h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #A2EB5E;
}

.features p {
  font-size: 1.5rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features h1 {
    font-size: 2rem;
  }
  .features h2 {
    font-size: 1.5rem;
  }
  .features p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .features h1 {
    font-size: 1.5rem;
  }
  .features h2 {
    font-size: 1.2rem;
  }
  .features p {
    font-size: 1rem;
  }
}

.feature {
  display: flex;
  text-align: left;
}

.feature img {
  width: 35vw;
}

.how-to-play h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #A2EB5E;
}

.how-to-play p {
  padding-left: 10px;
  font-size: 1.5rem;
  line-height: 1.6;
}

@media (max-width: 768px) {

  .how-to-play h2 {
    font-size: 2rem;
  }
  .how-to-play p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {

  .how-to-play h2 {
    font-size: 1.5rem;
  }
  .how-to-play p {
    font-size: 1rem;
  }
}

.how-to-play .duadanempat {
  padding-left: 10px;
}

.how-to-play img {
width: 3.5vw ;
/* height: 65px; */
}

.numbers {
  display: flex;
  text-align: left;
}

.about h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #A2EB5E;
}

.about p {
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 20px 0;
}


@media (max-width: 1300px) {
  .about {
    padding-top: 20vw;
  }
}

@media (max-width: 768px) {
  .about {
    padding-top: 30vw;
  }
  .about h2 {
    font-size: 2rem;
  }
  .about p {
    font-size: 1.2rem;
  }
}
@media (max-width: 560px) {
  .about {
    padding-top: 50vw;
  }
}

@media (max-width: 500px) {
  .about {
    padding-top: 40vw;
  }
  .about h2 {
    font-size: 1.5rem;
  }
  .about p {
    font-size: 1rem;
  }
}

@media (max-width: 490px) {
  .about {
    padding-top: 0;
  }
}

@media (max-width: 390px) {
  .about {
    padding-top: 20vw;
  }
}

@media (max-width: 360px) {
  .about {
    padding-top: 40vw;
  }
}

.about img {
  width: 12.65vw ;
  padding-left: 0.52vw;
  padding-right: 0.52vw;
  padding-top: 0.52vw;
}

.store-buttons{
  text-align: left;
  padding-top: 50px;
}

.store-buttons a {
  color: #A2EB5E; 
}

.store-buttons a:active {
  color: white;
}

.store-buttons a:hover {
  color: whitesmoke; 

}


.available-now img {
  width: 38vw ;
  height: 6.7vw ;
}

.available-now h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #A2EB5E;
}

.available-now p {
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 20px 0;
}

@media (max-width: 768px) {

  .available-now h2 {
    font-size: 2rem;
  }
  .available-now p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {

  .available-now h2 {
    font-size: 1.5rem;
  }
  .available-now p {
    font-size: 1rem;
  }
}

.footer{
display: flex;  
justify-content: space-between;
width: 68vw;
padding-left: 16vw;
padding-right: 16vw;
padding-top: 2vw;
padding-bottom: 2vw;
border-top: 0.8px solid #ffff; 
border-bottom: 0.8px solid #ffff; 
}

.subFooter{
  flex-direction: column;
  align-content: center;
}

.subFooter p {
  margin: 0;
  font-size: 0.8rem;
}
.subFooter h2 {
  margin: 0;
  font-size: 1rem;
}

.footerIcons{
  align-content: center;
}


.videodemoContent {
padding-left: 2.82vw;
padding-right: 0.52vw;
z-index: 10;
text-align: left;
display: flex;
flex-direction: column;
align-items: flex-start;
}

.videodemo {
  display: flex;
  text-align: left;
  z-index: 10;
  padding-left: 16%;
  padding-right: 16%;
  position: absolute;
  padding-top: 6vw;
  align-items: start;

}


.videodemo h2 {
  font-size: 2.5rem;
  margin: 0;
  z-index: 10;
}

.videodemo p {
  font-size: 1.5rem;
  line-height: 1.6;
  z-index: 10;
  margin-top: 5px;
}

.videodemos .videodemoBackground {
  width: 100vw;
  height: 27.39vw;
  object-fit: cover;
  opacity: 0.5;
}

.videodemos {
  width: 100vw;
  height: 27.39vw;
}

@media (max-width: 1500px) {
  .videodemos {
    padding-bottom: 25vw;
  }

  .videodemo {
    flex-direction: column;
    align-items: center;
  }

  .videodemoContent {
    padding-top: 5vw;
    padding-left: 0;

  }

  .videodemo h2 {
    padding-top: 3vw;
  }

  .buttonVideoDemo {
    width: 19.20vw;
    height: 4.50vw;  
  }
}

@media (max-width: 768px) {
  .videodemos {
    padding-bottom: 30vw;
  }

  .videodemo {
    flex-direction: column;
  }

  .videodemo h2 {
    font-size: 1.5rem;
    padding-top: 3vw;
  }
  .videodemo p {
    font-size: 1.2rem;
  }

  .buttonVideoDemo {
    width: 20.20vw;
    height: 5.50vw; 
    font-size: auto;
  }
}

@media (max-width: 490px) {
  .videodemos {
    padding-bottom: 100vw;
  }

  .videodemo {
    flex-direction: column;
  }

  .videodemo h2 {
    font-size: 1.2rem;
    padding-top: 2vw;

  }
  .videodemo p {
    font-size: 1rem;
  }

  .buttonVideoDemo {
    width: 21.20vw;
    height: 6.50vw; 
    font-size: auto;
  }
}

.buttonVideoDemo {
  width: 18.18vw;
  height: 3.49vw;
  margin: 0;
  float: left; 
  display: block; 
  background-color: #A2EB5E;
  color: black;
  border: none;
  border-radius: 8%;
  text-align: center;
  font-size: auto;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonVideoDemo:hover {
  background-color: green;
  color: white;
}

.buttonVideoDemo:active {
  background-color: white;
  color: black;
}

.videodemoView {
  width: 28.49vw;
  z-index: 10;
  border-radius: 10%;
  border: 2px solid #000000; 
}

.videodemoView:hover {
  border-radius: 10%;
  border: 2px solid #ffff; 
}
